// import csm from '../../../images/home/csm.webp';
// import csmhover from '../../../images/home/csmhover.webp';
// import paid from '../../../images/home/paid.webp';
// import paidhover from '../../../images/home/paidhover.webp';
// import ecomm from "../../../images/home/ecomm.webp";
// import ecommhover from "../../../images/home/ecommhover.webp";
import img1 from "../../../images/ksliveimages/1.webp";
import img2 from "../../../images/ksliveimages/2.webp";
import img3 from "../../../images/ksliveimages/3.webp";


export const cardData =[
  {
    img: img1,
    title: "Finding Your Perfect Course Match",
    desc: "Confused about which digital marketing specialization to choose from?",
    btnTxt: "Read Here",
    url: `/blog/which-digital-marketing-specialization-should-i-choose/`
  },
  {
    img: img2,
    title: "How to Crack Any Interview?",
    desc: "Learn how to use personal storytelling to crack any interview!",
    btnTxt: "Read Here",
    url: "/blog/personal-storytelling-to-crack-interviews/"
  },
  {
    img: img3,
    title: "Top Trending Fields in Digital Marketing",
    desc: "Ready to tap into the pulse of digital marketing trends?",
    btnTxt: "Read Here",
    url: `/blog/types-of-digital-marketing-fields/`
  }
];