import React from "react";

import YellowUnderlineImg from "../../../images/common/yellow-underline.webp";
import "./styles.scss";
import { Link } from "gatsby";
import { cardData } from "./data";

const KraftshalaExperience = () => {
  return (
    <section className="kraftshala-experience-main">
      <div className="header-container">
        <h1>Dive Into The Kraftshala Experience</h1>
        <img
          src={YellowUnderlineImg}
          alt="yellow underline decoration"
          loading="lazy"
          className="yellow-underline-img"
        />
      </div>
      <div className="cards-container">
        {
          cardData.map(data => {
            return (
              <React.Fragment key={data.title}>
                <KsExperienceCard cardData={data} />
              </React.Fragment>
            );
          })
        }
      </div>
    </section>
  );
};

const KsExperienceCard = ({cardData}) => {
  return (
    <div className="ks-experience-card">
      <div className="card-img-container">
        <img
          src={cardData.img}
          alt={cardData.title}
          loading="lazy"
          className="card-img" 
        />
      </div>
      <h3 className="card-title">
        {cardData.title}
      </h3>
      <div className="card-desc">
        {cardData.desc}
      </div>
      <Link
        to={cardData.url}
        className="card-action-btn"
      >
        {cardData.btnTxt}
      </Link>
    </div>      
  );
};

export default KraftshalaExperience;