import React, { useEffect, useState } from "react";
import Rating from "@mui/material/Rating";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";

import heroImg from "../../../images//common/ks-live-hero-img.webp";
// import bannerImg from "../../../images//common/live-banner-mob.webp";
import moment from "moment";
import "./styles.scss";

function HeroSection({ activeEvents }) {
  const [upcomingEvent, setUpcomingEvent] = useState({
    id: null,
    bannerImg: "",
    title: "",
    description: "",
    startDateTime: null,
  });

  useEffect(() => {
    if (activeEvents?.length) {
      setUpcomingEvent({
        id: activeEvents[0]?.id ?? "",
        bannerImg: activeEvents[0]?.banner ?? "",
        title: activeEvents[0]?.title ?? "",
        description: activeEvents[0]?.description ?? "",
        startDateTime: activeEvents[0]?.start_date_time ?? "",
      });
    }
  }, [activeEvents]);

  return (
    <section className="kflive-hero-section">
      <div className="kflive-meta">
        <div className="kflive-hero-img">
          <img src={heroImg} alt="ks live hero image" loading="lazy" />
        </div>
        <h1 className="kflive-meta-header">
          Kraftshala <span className="highlight-yl">LIVE</span>
        </h1>
        <div className="description">
          Don't miss out on this chance to learn from the best in the industry!
          Gain invaluable business insights from India's top sales and marketing
          leaders and practitioners - all at no cost.
        </div>
        <div className="stat-box">
          <Stack spacing={1}>
            <h3 className="participant-count">3000+</h3>
            <div className="stat-box-heading">Participants Trained</div>
          </Stack>
          <Divider orientation="vertical" sx={{ width: "1px !important" }} />
          <Stack spacing={1}>
            <div className="rating-div">
              <Rating
                name="half-rating-read"
                defaultValue={4.5}
                precision={0.5}
                readOnly
              />{" "}
              (4.5)
            </div>
            <div className="stat-box-heading">Average Rating</div>
          </Stack>
        </div>
      </div>
      <div className="upcoming-session">
        {upcomingEvent.id && (
          <div className="ks-live-registration-card">
            <div className="session-banner">
              <img
                src={upcomingEvent?.bannerImg}
                alt={`${upcomingEvent?.title} banner image`}
                loading="lazy"
              />
            </div>
            <div className="session-details-main">
              <div className="session-meta">
                {/* <div>
                      <b>Webinar: </b> {upcomingEvent?.title ?? "--"}
                  </div> */}
                <div>
                  <b>Date:</b>{" "}
                  {moment(upcomingEvent?.startDateTime).isValid()
                    ? moment(upcomingEvent?.startDateTime).format("ll")
                    : "--"}
                </div>
                <div>
                  <b>Day:</b>{" "}
                  {moment(upcomingEvent?.startDateTime).isValid()
                    ? moment(upcomingEvent?.startDateTime).format("dddd")
                    : "--"}
                </div>
                <div>
                  <b>Time:</b>{" "}
                  {moment(upcomingEvent?.startDateTime).isValid()
                    ? moment(upcomingEvent?.startDateTime).format("LT")
                    : "--"}
                </div>
              </div>
              <Divider
                orientation="vertical"
                sx={{ width: "1px !important", height: "6em" }}
              />
              <div className="session-about-info">
                {upcomingEvent?.description}
              </div>
            </div>
            <button
              className="register-btn"
              onClick={() =>
                window.open(
                  `/kraftshala-live/register/${upcomingEvent?.id}`,
                  "_top"
                )
              }
            >
              Register Now
            </button>
            <div className="upcoming-tag">Upcoming</div>
          </div>
        )}
      </div>
    </section>
  );
}

export default HeroSection;
